* {
  --mainColor: #38D770;
  box-sizing: border-box;
}

.loader {
  width: 24px;
  height: 24px;
  border: 2px solid #FFF;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: Poppins;
  margin: 0;
  background-color: #171717;
  color: #ffffff;
}

.home {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.home .scan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .scan .qr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: var(--mainColor);
  position: absolute;
  z-index: -1;
  right: 0;
  display: flex;
  margin: auto;
}

.home .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 50%;
  height: 100%;
  padding: 32px;
  box-shadow: 0px 4px 12px #00000050;
  z-index: 99;
  margin-top: 48px;
  color: #000000;
}

.home .verify {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .verify .title {
  font-size: 18px;
}

.home .scan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.home .scan .steps {
  display: flex;
  flex-direction: column;
}

.home .scan .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 18px;
}

.home .verify .code {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.home .verify .pin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  margin: 12px;
  width: 54px;
  height: 54px;
  border-radius: 8px;
  border: 1px solid gainsboro;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #00000090;
  border-bottom: 1px solid #ffffff20;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  padding: 12px 18px;
  z-index: 9999;
}

.header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header .logo img {
  width: 24px;
  margin-right: 12px;
}

.header .logo span {
  font-size: 24px;
  color: #ffffff;
}

.header .menu .item {
  margin-right: 24px;
}

.header .start {
  border: 1px solid #ffffff20;
  color: #ffffff;
  background: #ffffff40;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
}

input:focus {
  outline: 2px solid #41ED7C;
}

@media only screen and (max-width: 928px) {
  .box {
    width: 90% !important;
  }

  .home .scan {
    flex-direction: column;
  }

  .home .scan .steps {
    margin-bottom: 28px;
  }

  .blogs {
    width: 100% !important;
  }

  .blogs .blog {
    min-width: 100% !important;
    margin-right: 0px;
  }

  .features {
    width: 100% !important;
  }

  .features .feature .cover {
    width: 128px !important;
    height: 128px !important;
  }

  .stores {
    display: flex;
    flex-direction: column;
  }

  .stores .store {
    margin-left: 0px !important;
    margin-bottom: 24px;
  }

  .app {
    width: 100% !important;
  }

  .app .user-plan .perks {
    width: 100% !important;
  }

  .app .user-plan .plans {
    flex-direction: column !important;
  }

  .app .user-plan .plans .subscription {
    margin-right: 0px !important;
    margin-bottom: 14px;
    width: 100% !important;
  }

  .email-verification_inner {
    flex-direction: column;
  }

  .app .email-verification .send {
    margin-left: 0px !important;
    margin-top: 12px;
  }

  .popup_inner {
    width: 100% !important;
  }

  .app .user-plan .plans .subscription {
    justify-content: space-between;
    flex-direction: row !important;
  }

  .app .popup .popup_inner {
    min-width: 0px !important;
  }

  .app_header .inner {
    width: 100% !important;
  }
}

.home .verified {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .verified img {
  width: 128px;
}

.home .verified .title {
  font-size: 24px;
  margin-top: 24px;
}

.home .download {
  margin: 48px 0px 32px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home .download .title {
  margin-bottom: 22px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

.home .download .stores {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .download .stores .store {
  display: flex;
  align-items: center;
  color: #000000;
  margin-left: 32px;
  background: #ffffff;
  padding: 4px 32px;
  text-decoration: none;
  border-radius: 8px;
}

.home .download .stores .store:first-child {
  margin-left: 0px;
}

.home .download .stores .store .icon {
  width: 32px;
  margin-right: 16px;
}

.home .download .stores .store .details {
  display: flex;
  flex-direction: column;
}

.home .download .stores .store .alt {
  font-size: 12px;
}

.home .download .stores .store .text {
  font-weight: 500;
  color: #000000;
  font-size: 21px;
  margin-top: -4px;
}

.home .feature-left {
  margin-right: auto;
  text-align: left;
}

.home .feature-left .details {
  margin-left: 24px;
}

.home .feature-right {
  margin-left: auto;
  text-align: right;
}

.home .feature-right .details {
  margin-right: 24px;
}

.home .features {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.home .feature {
  width: 100%;
  display: flex;
  padding: 24px;

}

.home .feature .cover {
  width: 256px;
  border-radius: 18px;
  box-shadow: 0px 4px 12px #04040450;
  border: 1px solid #ffffff20;
}

.home .feature .details {
  display: flex;
  flex-direction: column;
}

.home .feature .details .title {
  font-size: 24px;
  font-weight: 500;
}

.home .feature .details .description {
  font-size: 16px;
  margin-top: 8px;
}

.app {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  padding: 24px;
  margin-top: 64px;
}

.app .notification-status {
  display: flex;
}

.app .area {
  padding: 18px 26px;
}

.app .user-plan {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff20;
  border-radius: 12px;
  margin-bottom: 12px;
  background: #222222;
}

.app .user-plan .plans {
  display: flex;
}

.app .user-plan .perks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  width: 50%;
  margin: auto;
}

.app .user-plan .alt_info {
  font-size: 14px;
  margin-bottom: 16px;
}

.app .user-plan .perks .perk {
  display: flex;
  align-items: center;
  padding-bottom: 16px;

}

.app .user-plan .perks .perk .icon {
  margin-right: 12px;
  font-size: 24px;
}

.app .user-plan .plans {
  margin-bottom: 8px;
}

.app .user-plan .plans .subscription {
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 25%;
  border: 1px solid #ffffff20;
  background: #333;
  border-radius: 6px;
  margin-right: 14px;
  transition: 0.1s ease-in;
  cursor: pointer;
}

.app .user-plan .plans .subscription .sub_details {
  display: flex;
  flex-direction: column;
}

.app .user-plan .plans .subscription .price {
  margin-top: 12px;
  opacity: 0.7;
}

.app .user-plan .plans .subscription:hover {
  transform: scale(1.04);
}

.app .user-plan .plans .subscription:last-child {
  margin-right: 0px;
}

.app .user-plan .plans .subscription_selected {
  background: var(--mainColor);
}

.app .user-plan .continue {
  background: var(--mainColor);
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 12px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 14px;
}

.app .user-plan .continue:hover {
  opacity: 0.7;
}

.app .notification-status {
  border: 1px solid #ffffff20;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  background: #222222;
}

.app .notification-status .icon {
  margin-right: 12px;
  font-size: 22px;
}

.app .notification-status .check {
  color: var(--mainColor);
}

.app .email-verification {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff20;
  border-radius: 12px;
  margin-bottom: 12px;
  background: #222222;
}

.app .email-verification .email-verification_inner {
  display: flex;
}

.app .input,
.PhoneInputInput {
  border: 1px solid #ffffff20;
  background: #333;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
}

.app .email-verification .send {
  margin-left: 24px;
  background: var(--mainColor);
  padding: 12px 14px;
  border-radius: 12px;
  font-size: 14px;
  min-width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.app .email-verification .send:hover {
  opacity: 0.9;
}

.app .phone-list {
  margin-top: 18px;
}

.app .phone-list .phones {
  display: flex;
  flex-direction: column;
}

.app .phone-list .title {
  font-size: 20px;
}

.app .phone-list .phones .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #ffffff20;
  padding: 18px 0px;
  justify-content: space-between;
  text-decoration: none;
  color: #ffffff;
}

.app .phone-list .phones .item_active:hover {
  background: #ffffff20;
}

.app .phone-list .phones .item .info .id {
  display: flex;
  align-items: center;
}

.app .phone-list .phones .item .info {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.app .phone-list .phones .item .avatar {
  width: 42px;
  border-radius: 100px;
}

.app .phone-list .phones .item .details {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  width: 100%;
}

.app .phone-list .phones .item .chev {
  display: flex;
  align-items: center;
}

.app .phone-list .phones .item .scan-qr {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  width: 100%;
}

.app .phone-list .phones .item .scan-qr .showqrbtn {
  padding: 8px 14px;
  border-radius: 100px;
  background: #38D770;
  width: fit-content;
  cursor: pointer;
  margin-top: 12px;
}

.app .phone-list .phones .item .scan-qr .scan-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #222222;
  width: 100%;
  margin-top: 18px;
  border: 1px solid #ffffff20;
  border-radius: 12px;
}

.app .phone-not-added {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.app .phone-not-added .info {
  font-size: 15px;
  text-align: center;
  opacity: 0.7;
}

.app .add-number {
  border: 1px solid #ffffff20;
  background: var(--mainColor);
  padding: 12px 26px;
  border-radius: 12px;
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-sizing: content-box;
}

.app .add-number span {
  text-align: center;
}

.app .add-number:hover {
  opacity: 0.7;
}

.app .add-number .icon {
  margin-right: 12px;
  font-size: 18px;
}

.app .popup .add-number {
  margin-top: 32px;
}

.app .popup {
  background: #00000060;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  animation-name: popup;
  animation-duration: 0.2s;
}

.app .popup .popup_inner {
  background: #171717;
  border-radius: 12px;
  border: 1px solid #ffffff20;
  padding: 24px 42px;
  z-index: 9;
  min-width: 512px;
}

.app .popup .popup_inner .popup_title {
  font-size: 24px;
}

.app .popup .popup_inner .popup_content {
  margin-top: 20px;
}

.app .popup .popup_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

@keyframes popup {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.app .phone_name {
  margin-bottom: 12px;
}

.input:focus {
  border: 1px solid #ffffff20 !important;
}

.app .languages {
  margin-top: 32px;
}

.app .languages .list {
  display: flex;
  margin-top: 12px;
}

.app .languages .list .item {
  margin-right: 8px;
  border-radius: 100px;
  background: #ffffff20;
  cursor: pointer;
  padding: 12px 18px;
  font-size: 13px;
}

.center {
  margin: 36px auto;
}

.app .app-qr {
  margin: 12px 0px;
}

.app .phone-list .phones .detailsbtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  opacity: 0.4;
}

.app .phone-list .phones .detailsbtn span {
  margin-right: 12px;
}

.app .phone-list .phones .phone_number {
  font-size: 13px;
  opacity: 0.6;
}

.app .phone-list .phones .last_seen {
  font-size: 13px;
  opacity: 0.6;
}

.app .phone-list .phones .actions {
  font-size: 38px;
  padding: 10px;
  padding-right: 0px;
  cursor: pointer;
}

.app .phone-list .phones .action_details {
  opacity: 0.6;
}

.app .phone-list .phones .action_trash {
  color: tomato;
}

.app_phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app_phone .phone_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid #ffffff20;
}

.app_phone .phone_actions .phone_delete_btn {
  display: flex;
  align-items: center;
  color: tomato;
  cursor: pointer;
  padding: 12px 18px;
  border-radius: 12px;
}

.app_phone .phone_actions .phone_delete_btn:hover {
  background: #ff634720;
}

.app_phone .phone_delete_btn span {
  margin-left: 6px;
}

.app_phone .phone_track_history {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.app_phone .phone_track_history .title {
  font-size: 20px;
  margin-bottom: 14px;
}

.app_phone .phone_track_history .list {
  display: flex;
  flex-direction: column;
}

.app_phone .phone_track_history .list .item_outer {
  margin-bottom: 24px;
}

.app_phone .phone_track_history .list .item {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 12px;
  overflow: hidden;
  background: #222222;
}

.app_phone .phone_track_history .list .item .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ffffff10;
  font-size: 15px;
}

.app_phone .phone_track_history .list .item .section:last-child {
  border-bottom: none;
}

.app_phone .avatar {
  border-radius: 100px;
  margin-bottom: 12px;
}

.app_phone .phone_track_history .list .item .section .prefix {
  display: flex;
  align-items: center;
}

.app_phone .phone_track_history .list .item .section .online {
  color: #38D770;
}

.app_phone .phone_track_history .list .item .section .offline {
  color: tomato;
}

.app_phone .phone_track_history .list .item .section .prefix .icon {
  margin-right: 8px;
}

.app_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #00000090;
  border-bottom: 1px solid #ffffff20;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 9999;
  width: 100%;
  justify-content: center;
}

.app_header .inner {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  padding: 14px 24px;

  justify-content: space-between;
}

.app_header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.app_header .logo img {
  width: 24px;
  margin-right: 12px;
}

.app_header .logo span {
  font-size: 18px;
  color: #ffffff;
}

.app .joinus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ffffff10;
  margin-bottom: 16px;
  text-align: center;
  justify-content: center;
}

.app .joinus .title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
}

.app .joinus .screens {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
}

.app .joinus .screens img {
  width: 25%;
  padding: 12px;
}

.app .joinus .awards {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
}

.app .joinus .awards .award {
  width: 172px;
  padding: 12px;
  filter: invert(1) saturate(0) brightness(2);
}

.app .joinus .seenon {
  width: 272px;
  margin-top: 32px;
  filter: invert(1) saturate(0) brightness(2);
}

.app .joinus .star_review {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.app .joinus .stars {
  font-size: 18px;
  color: gold;
  margin-right: 8px;
}

.app .joinus .star_review span {
  opacity: 0.6;
}

.app .joinus .customer_reviews {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.app .joinus .customer_reviews .review {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  font-size: 13px;
  width: calc(50% - 8px);
  margin: 4px;
  background: #222222;
  padding: 18px;
}

.app .joinus .customer_reviews .review .name {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.app .joinus .customer_reviews .review .comment {
  font-weight: 300;
  margin-bottom: 8px;
}

.app .joinus .desc {
  font-weight: 300;
}

.app .joinus .video {
  margin: 12px 0px;
  width: 100%;
  height: 328px;
}